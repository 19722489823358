<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            required
            label="Description"
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field label="Occurrence Date & Time">
            <asom-input-date-time v-model="formData.occurrenceDate" />
          </asom-form-field>
          <div>
            <asom-form-field
              :label="'Is Expiry Date Required'"
              :state="inputStates('formData.isExpiryDateRequired')"
              error="Please check"
            >
              <asom-input-radio-group
                class="mt-3"
                horizontal
                v-model="formData.isExpiryDateRequired"
                :options="expireDateOptions"
                :state="inputStates('formData.isExpiryDateRequired')"
              />
            </asom-form-field>
            <asom-form-field
              v-if="formData.isExpiryDateRequired"
              label="Expiry Date"
              required
              :state="inputStates('formData.expiryDate')"
              error="Expiry Date is required"
            >
              <asom-input-date
                :min-date="new Date()"
                v-model="formData.expiryDate"
                :state="inputStates('formData.expiryDate')"
              />
            </asom-form-field>
          </div>
          <asom-form-field
            :label="'Item Status'"
            :state="inputStates('formData.isItemOpened')"
            error="Please select"
          >
            <asom-input-radio-group
              class="mt-3"
              horizontal
              v-model="formData.isItemOpened"
              :options="itemStatusOptions"
              :state="inputStates('formData.isItemOpened')"
            />
          </asom-form-field>
          <asom-form-field
            label="Remarks"
            :state="inputStates('formData.remarks')"
            error="Remarks is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Supporting documents"
            :state="inputStates('formData.files')"
            error="Supporting documents should be Images or PDFs"
          >
            <asom-upload
              v-model="formData.files"
              :category="attachmentCategories.FAULT_MANAGEMENT"
              :state="inputStates('formData.files')"
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Create"
              @click="createFaultOccurence"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { createFaultOccurrence } from "../../../services/faultManagement.service";
import { onlyImagePDF } from "@/helpers/customValidations";
import { parseDateTimeToUtc } from "../../../helpers/dateTimeHelpers";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      formData: {
        description: null,
        expiryDate: null,
        occurrenceDate: new Date(),
        isItemOpened: true,
        isExpiryDateRequired: false,
        files: [],
        remarks: null,
      },
    };
  },
  validations() {
    let validations = {
      formData: {
        description: { required },
        files: { onlyImagePDF },
      },
    };
    if (this.formData.isExpiryDateRequired) {
      validations.formData.expiryDate = { required };
    }
    return validations;
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      userLineId: "auth/userLineId",
    }),
    itemStatusOptions() {
      return [
        { value: true, label: "Open" },
        { value: false, label: "Closed" },
      ];
    },
    actionStatusOptions() {
      return [
        { value: true, label: "Action Required" },
        { value: false, label: "No Action Required" },
      ];
    },
    expireDateOptions() {
      return [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ];
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  methods: {
    createFaultOccurence() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }
      this.isSubmitting = true;
      const result = await createFaultOccurrence({
        stationId: this.stationId,
        lineId: this.userLineId,
        description: this.formData.description,
        isItemOpened: this.formData.isItemOpened,
        isExpiryDateRequired: this.formData.isExpiryDateRequired,
        expiryDate: this.formData.expiryDate,
        attachments: uploadedIds,
        remarks: this.formData.remarks,
        occurrenceDate: this.formData.occurrenceDate
          ? parseDateTimeToUtc(this.formData.occurrenceDate)
          : null,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
