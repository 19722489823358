import { helpers } from "@vuelidate/validators";
import moment from "moment";
import get from "lodash.get";

/**
 * Custom validation to validate min date
 */
export const minDate = (dateParam) => (value) => {
  return (
    !helpers.req(value) ||
    (moment(value).isValid() &&
      moment(dateParam).isValid() &&
      moment(value).isSameOrAfter(moment(dateParam), "day"))
  );
};

/**
 * Custom validation to validate min date
 */
export const maxDate = (dateParam) => (value) => {
  return (
    !helpers.req(value) ||
    (moment(value).isValid() &&
      moment(dateParam).isValid() &&
      moment(value).isSameOrBefore(moment(dateParam), "day"))
  );
};

/**
 * Custom validation to validate min date time
 */
export const minDateTime = (dateParam) => (value) => {
  return (
    !helpers.req(value) ||
    (moment(value).isValid() &&
      moment(dateParam).isValid() &&
      moment(value).isSameOrAfter(moment(dateParam), "minute"))
  );
};

/**
 * Custom validation to validate min date time
 */
export const maxDateTime = (dateParam) => (value) => {
  return (
    !helpers.req(value) ||
    (moment(value).isValid() &&
      moment(dateParam).isValid() &&
      moment(value).isSameOrBefore(moment(dateParam), "minute"))
  );
};

/**
 * Custom validation to validate uploaded files are in image or pdf formats
 */
export const onlyImagePDF = (files) => {
  let status = true;
  const getFileType = (fileName) => {
    if (fileName !== null) {
      let nameArr = fileName.split(".");
      return nameArr[nameArr.length - 1];
    }
  };
  files.forEach((file) => {
    let fileType = getFileType(get(file, "fileName", null));
    if (
      !["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "pdf", "PDF"].includes(
        fileType
      )
    )
      status = false;
  });
  return status;
};
